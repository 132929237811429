/* eslint-disable */
require('./scss/base.scss');
document.addEventListener('touchstart', function () {
}, false);

declare let __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + '/' + __webpack_public_path__;
/* eslint-enable */

import {createApp, defineAsyncComponent, Plugin} from 'vue';

import Icons from '@mcam/mcam-storybook/dist/cjs/icons.js';
import Graphics from '@mcam/mcam-storybook/dist/cjs/graphics.js';
import '@mcam/mcam-storybook/dist/cjs/vendors.js';

import {store} from '@/store';
import enterFade from '@/directives/enterFade';
import enterUpFade from '@/directives/enterUpFade';
import imageLoaded from '@/directives/imageLoaded';
import {i18n} from '@/i18n-setup';
import {createPinia} from "pinia";
import {Swiper, SwiperSlide} from 'swiper/vue';

const app = createApp({
    components: {
        FormUpload: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/FormUpload.js")),
        FormComponent: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/FormComponent.js")),
        SimpleFormComponent: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/SimpleFormComponent.js")),
        Field: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/Field.js")),
        TextAreaField: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/TextAreaField.js")),
        CheckboxField: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/CheckboxField.js")),
        VideoComponent: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/ui/VideoComponent.js")),
        Lightbox: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/util/Lightbox.js")),
        SelectField: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/form/SelectField.js")),
        Navigation: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/ui/Navigation.js")),
        EngineeringCard: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/card/EngineeringCard.js")),
        Slider: defineAsyncComponent(() => import("@mcam/mcam-storybook/dist/cjs/ui/Slider.js")),

        CompetitionDataTable: defineAsyncComponent(() => import("./components/CompetitionsDataTable.vue")),
        TheModal: defineAsyncComponent(() => import("./components/TheModal.vue")),
        FaqQuestion: defineAsyncComponent(() => import("./components/FaqQuestion.vue")),
        CaseStudies: defineAsyncComponent(() => import("./components/CaseStudies.vue")),
        BackToTop: defineAsyncComponent(() => import("./components/BackToTop.vue")),
        Statistic: defineAsyncComponent(() => import("./components/Statistic.vue")),
        AccountInformationForm: defineAsyncComponent(() => import("./components/AccountInformationForm.vue")),
        SubmissionsOverview: defineAsyncComponent(() => import("./components/SubmissionsOverview/SubmissionsOverview.vue")),
        Notification: defineAsyncComponent(() => import("./components/Notification.vue")),
        ApplyChallengeForm: defineAsyncComponent(() => import("./components/ApplyChallengeForm/ApplyChallengeForm.vue")),

        ChallengeTabContent: defineAsyncComponent(() => import("./components/Challenges/ChallengeTabContent.vue")),
        ChallengeCard: defineAsyncComponent(() => import("./components/Challenges/ChallengeCard.vue")),

        AdminRouter: defineAsyncComponent(() => import("./components/Administration/AdminRouter.vue")),
        CompetitionsDataOverview: defineAsyncComponent(() => import("./components/CompetitionsDataOverview.vue")),
        CompetitionOverview: defineAsyncComponent(() => import("./components/Competition/CompetitionOverview.vue")),

        NewsletterForm: defineAsyncComponent(() => import("./components/Forms/NewsletterForm.vue")),
        ContactForm: defineAsyncComponent(() => import("./components/Forms/ContactForm.vue")),

        Swiper,
        SwiperSlide
    },
    directives: {
        imageLoaded,
        enterFade,
        enterUpFade,
    }
});
app.config.performance = true;
app.config.compilerOptions.comments = true;

app.use(Icons as Plugin); // Register all icons from @mcam/mcam-storybook
app.use(Graphics as Plugin); // Register all graphics from @mcam/mcam-storybook

app.use(createPinia());
app.use(store);
app.use(i18n);

app.mount('#app');