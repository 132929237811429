import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ObjectDirective} from 'vue';

const imageLoaded: ObjectDirective = {
    beforeMount: (el, binding) => {
        el.addEventListener('load', () => {
            ScrollTrigger.refresh(false);
        });
    },
};
export default imageLoaded;
