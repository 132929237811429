import process from '@/utils/propertyFileProcessor';
import { createI18n } from 'vue-i18n';
// @ts-ignore
import enProperties from '../../growth-garage/i18n/module-growth-garage-frontend_en.properties';

const en = process(enProperties);

export const i18n = createI18n({
    legacy: false,
    locale: _mgnl.language,
    globalInjection: true,
    fallbackLocale: 'en',
    messages: {
        en,
    }
});