import Vue from "vue";
import { createStore } from 'vuex';
import modal, { Modal } from './modules/modal';

const debug = process.env.NODE_ENV !== 'production';

export declare interface StoreModules {
  modal: Modal;
}

export const store = createStore<StoreModules>({
  modules: {
    modal
  },
  strict: debug
})
