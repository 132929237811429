import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ObjectDirective} from 'vue';

gsap.registerPlugin(ScrollTrigger);

const enterUpFade: ObjectDirective = {
    mounted: (el) => {
        const triggerSelector = el.dataset.triggerSelector || '.container';
        let trigger: HTMLElement;
        if (triggerSelector === 'self') {
            trigger = el;
        } else {
            trigger = el.closest(triggerSelector) || el;
        }

        gsap.from(
            el,
            {
                y: '25%',
                opacity: 0,
                scrollTrigger: {
                    trigger,
                    start: 'top 100%',
                    end: 'top 50%',
                    scrub: true,
                    markers: false,
                },
            },
        );
    },
};
export default enterUpFade;
